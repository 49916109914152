import { Box, Typography } from '@mui/material';
import { Redirect } from 'react-router-dom';

import OfferCategoriesList from 'components/OfferCategoriesList';
import OffersDisclosure from 'components/OffersDisclosure';
import Page from 'components/Page';
import PageLoader from 'components/PageLoader';
import { theme } from 'context/ThemeProvider';
import useApplicationStatus from 'hooks/useApplicationStatus';
import { ROUTES } from 'components/Routes';

export const OFFERS_REWARDS_STATUSES = [
  'completed',
  'paidOnSchedule',
  'paidEarly',
];

const OfferCategoriesPage: React.FC = () => {
  const { data, isLoading } = useApplicationStatus();

  if (isLoading || !data) {
    return <PageLoader />;
  }

  if (!OFFERS_REWARDS_STATUSES.includes(data.applicationStatus)) {
    return <Redirect to={ROUTES.APP} />;
  }

  return (
    <Page>
      <Box
        maxWidth="sm"
        sx={{
          [theme.breakpoints.up('md')]: {
            textAlign: 'center',
            margin: '0 auto',
          },
        }}
      >
        <Typography variant="h1" marginBottom={theme.spacing(1)}>
          Offers
        </Typography>

        <Typography marginBottom={theme.spacing(3)}>
          <div>View offers from in-network partners.</div>
          <OffersDisclosure />
        </Typography>
      </Box>

      <OfferCategoriesList />
    </Page>
  );
};

export default OfferCategoriesPage;
