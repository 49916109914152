import { Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import InstallmentsContractForm from 'components/InstallmentsContractForm';
import OnboardingPage from 'components/OnboardingPage';
import PageLoader from 'components/PageLoader';
import { AuthContext } from 'context/AuthProvider';
import { theme } from 'context/ThemeProvider';
import * as impact from 'helpers/impact';
import useApplicationStatus from 'hooks/useApplicationStatus';
import { ROUTES } from 'components/Routes';

const ContractPage: React.FC = () => {
  const { data, isLoading } = useApplicationStatus();
  const { currentUser, username, email } = useContext(AuthContext);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (currentUser) {
      impact.identify({
        customerId: username,
        customerEmail: email,
      });
    }
  }, [data, currentUser, isLoading, username, email]);

  if (isLoading) {
    return <PageLoader />;
  }

  if (data && data.applicationStatus !== 'signatureNeeded') {
    return <Redirect to={ROUTES.APP} />;
  }

  return (
    <OnboardingPage onboardingStep={4} supportSubject="esign">
      <Typography variant="h1" marginBottom={theme.spacing(1.5)}>
        Review agreement
      </Typography>

      <InstallmentsContractForm />
    </OnboardingPage>
  );
};

export default ContractPage;
