import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Toolbar,
} from '@mui/material';
import { useProductAnalytics } from 'libs/productAnalyticsTracking';
import { useRef } from 'react';
import { Link } from 'react-router-dom';

import KovoLogo from 'components/KovoLogo';
import { theme } from 'context/ThemeProvider';
import { INVITING_REWARD_AMOUNT } from 'helpers/referralClaims';

import HomeIcon from 'assets/img/icons/home-icon.svg';
import { ROUTES } from 'components/Routes';

export interface Props {
  hideMenuButton?: boolean;
  showReferralButton?: boolean;
}

const Header: React.FC<Props> = ({ hideMenuButton, showReferralButton }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { track } = useProductAnalytics();

  return (
    <AppBar
      position="static"
      className="Header"
      sx={{
        boxShadow: 0,
        backgroundColor: 'transparent',
        padding: theme.spacing(1, 0),
        margin: 0,

        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(2, 0),
        },
      }}
      data-testid="app-bar"
      ref={ref}
    >
      <Container
        fixed
        sx={{
          position: 'relative',
          backgroundColor: 'transparent',
          padding: theme.spacing(0, 1.5),

          [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, 3),
            maxWidth: '746px',
          },
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: 0,

            [theme.breakpoints.up('sm')]: {
              minHeight: 0,
            },
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              textAlign: 'center',
            }}
          >
            <KovoLogo />
          </Box>

          {!hideMenuButton && (
            <IconButton
              size="large"
              aria-label="Go to main navigation"
              component={Link}
              to={ROUTES.APP}
              color="primary"
              data-testid="menu-button"
              sx={{
                position: 'absolute',
                left: theme.spacing(-1.125),
                padding: theme.spacing(1.5, 1.125),

                [theme.breakpoints.up('md')]: {
                  left: 0,
                  padding: theme.spacing(2, 1.5),
                },
              }}
            >
              <Box
                component="img"
                src={HomeIcon}
                alt="Main Navigation"
                sx={{
                  [theme.breakpoints.up('md')]: {
                    width: '35px',
                    height: '28px',
                  },
                }}
              />
            </IconButton>
          )}

          {showReferralButton && (
            <Button
              component={Link}
              to="/invite-friends"
              size="small"
              variant="outlined-gradient"
              sx={{
                fontWeight: 400,
                position: 'absolute',
                right: theme.spacing(0),
                padding: theme.spacing(0, 1),
                fontSize: '13px',
                textTransform: 'none',

                [theme.breakpoints.up('md')]: {
                  padding: theme.spacing(1, 2),
                  fontSize: '16px',
                },
              }}
              onClick={() =>
                track({
                  namespace: 'ui',
                  event: 'header.referral-button.clicked',
                })
              }
            >
              Get {INVITING_REWARD_AMOUNT}
            </Button>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
