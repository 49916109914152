import config from 'config';

// Feature flags
export const FLAG_IDV_MIGRATED = 'IDV_MIGRATED';

const FEATURE_FLAG_LOCAL_OVERRIDES: Record<string, string | boolean> = {
  // Add feature flags here for local development
};

export const getFeatureFlagValue = <T extends string | boolean>(
  featureFlag: string,
): T | undefined => {
  if (config.VITE_STAGE === 'local') {
    console.log(
      '[⚛︎] Requested feature flag in local environment:',
      featureFlag,
    );
    if (FEATURE_FLAG_LOCAL_OVERRIDES[featureFlag] !== undefined) {
      console.log(
        `[⚛︎] Overriding feature flag ${featureFlag} with ${FEATURE_FLAG_LOCAL_OVERRIDES[featureFlag]}`,
      );
      return FEATURE_FLAG_LOCAL_OVERRIDES[featureFlag] as T;
    }
  }
  const value = window.posthog?.getFeatureFlag(featureFlag);
  if (value === undefined) {
    return undefined;
  }
  return value as T;
};

export const isFeatureFlagEnabled = (featureFlag: string): boolean => {
  if (config.VITE_STAGE === 'local') {
    console.log(
      '[⚛︎] Requested feature flag in local environment:',
      featureFlag,
    );
    if (FEATURE_FLAG_LOCAL_OVERRIDES[featureFlag] !== undefined) {
      console.log(
        `[⚛︎] Overriding feature flag ${featureFlag} with ${FEATURE_FLAG_LOCAL_OVERRIDES[featureFlag]}`,
      );
      return Boolean(FEATURE_FLAG_LOCAL_OVERRIDES[featureFlag]);
    }
  }
  const isEnabled = window.posthog?.isFeatureEnabled(featureFlag);
  return isEnabled === true;
};
