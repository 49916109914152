import { Typography } from '@mui/material';
import { useProductAnalytics } from 'libs/productAnalyticsTracking';
import { Redirect, useHistory } from 'react-router-dom';

import OnboardingPage from 'components/OnboardingPage/OnboardingPage';
import PageLoader from 'components/PageLoader/PageLoader';
import PhoneNumberForm from 'components/PhoneNumberForm';
import { theme } from 'context/ThemeProvider';
import useApplicationStatus from 'hooks/useApplicationStatus';
import { ROUTES } from 'components/Routes';

const PhoneNumberPage: React.FC = () => {
  const history = useHistory();
  const { track } = useProductAnalytics();
  const { data: applicationData, isLoading } = useApplicationStatus();

  const onPhoneNumberSubmitted = () => {
    track({
      namespace: 'user',
      event: 'phone.submission.succeeded',
      sendAsConversionEventToMobileApp: true,
    });
    history.push('/verify-phone-number');
  };

  if (isLoading) {
    return <PageLoader />;
  }

  if (!applicationData) {
    return null;
  }

  if (!['new', 'detailsNeeded'].includes(applicationData.applicationStatus)) {
    return <Redirect to={ROUTES.APP} />;
  }

  if (!!applicationData.applicationDetails?.phoneNumber) {
    return <Redirect to="/apply" />;
  }

  return (
    <OnboardingPage onboardingStep={1} supportSubject="add-phone-number">
      <Typography variant="h1" marginBottom={theme.spacing(1.5)}>
        Add your number
      </Typography>

      <Typography marginBottom={theme.spacing(3)}>
        We need to verify your mobile phone number to start your application.
      </Typography>

      <PhoneNumberForm onSuccess={onPhoneNumberSubmitted} />

      <Typography variant="footnote" marginTop={theme.spacing(3)}>
        By sending a code, you are confirming this mobile phone number belongs
        to you and authorizing Kovo to text you at this number.
      </Typography>
    </OnboardingPage>
  );
};

export default PhoneNumberPage;
