export const ROUTES = {
  COURSES: '/courses',
  CREDIT_LINE_HOW_IT_WORKS: '/credit-line/how-it-works',
  CREDIT_LINE_CONTRACT: '/credit-line/review-contract',
  CREDIT_LINE_PAYMENT: '/credit-line/payment',
  CREDIT_LINE_UPDATE_CARD: '/credit-line/update-card',
  CREDIT_LINE_CATCHUP_PAYMENT: '/credit-line/catchup-payment',
  CREDIT_LINE: '/credit-line',
  CREDIT_PROTECTION: '/credit-protection',
  PHONE_NUMBER: '/phone-number',
  VERIFY_PHONE_NUMBER: '/verify-phone-number',
  APPLY: '/apply',
  INSTALLMENT_CATCHUP_PAYMENT: '/installment-plan/catchup-payment',
  SETTINGS: '/settings',
  SETTINGS_UPDATE_EMAIL: '/settings/update-email',
  SETTINGS_UPDATE_EMAIL_CONFIRMATION: '/settings/update-email-confirmation',
  UPFRONT_PAYMENT: '/upfront-payment',
  /**
   * Root route. This redirects to the app route
   */
  ROOT: '/',
  /**
   * App route. This is the main route for the app
   */
  APP: '/app',
  INSTALLMENT_PLAN: '/installment-plan',
  INSTALLMENT_PLAN_UPDATE_CARD: '/installment-plan/update-card',
  ERROR_500_ROUTE: '/500',
  LOGIN_ROUTE: '/login',
  SIGNUP_PENDING_ROUTE: '/signup-pending',
  SIGNUP_CONFIRM_ROUTE: '/signup-confirm',
  FORGOT_EMAIL_ROUTE: '/forgot-email',
};
