import { Typography } from '@mui/material';
import { Redirect } from 'react-router-dom';

import OnboardingPage from 'components/OnboardingPage';
import PageLoader from 'components/PageLoader';
import { theme } from 'context/ThemeProvider';
import { ROUTES } from 'components/Routes';
import useApplicationStatusV2 from 'hooks/useApplicationStatusV2';

const AdverseActionAge: React.FC = () => {
  // TODO update with proper createdAt
  const { data, isLoading } = useApplicationStatusV2();

  if (isLoading || !data) {
    return <PageLoader />;
  }

  if (data && data.status !== 'REJECTED' && data?.rejection?.code !== 'AGE') {
    return <Redirect to={ROUTES.APP} />;
  }

  return (
    <OnboardingPage supportSubject="adverse action">
      <Typography variant="h1" marginBottom={theme.spacing(2)}>
        Thank you for your interest in Kovo!
      </Typography>
      <Typography marginBottom={theme.spacing(1)}>
        We appreciate your enthusiasm for building credit with Kovo!
      </Typography>
      <Typography marginBottom={theme.spacing(1)}>
        Based on the birth date submitted, it looks like you're currently under
        18 years old. This means we're unable to accept your application right
        now, due to legal requirements.
      </Typography>
      <Typography marginBottom={theme.spacing(3)}>
        But don't worry:{' '}
        <strong>
          once you turn 18, we'll send you an offer to re-apply so you can start
          building credit with Kovo right away!
        </strong>
      </Typography>

      <div style={{ borderTop: '1px dashed #8C8C93' }} />

      <Typography marginTop={theme.spacing(3)}>
        <b>Notice:</b>&nbsp;The Federal Equal Credit Opportunity Act prohibits
        creditors from discriminating against credit applicants on the basis of
        race, color, religion, national origin, sex, marital status, age
        (provided the applicant has the capacity to enter into a binding
        contract); because all or part of the applicant's income derives from
        any public assistance program; or because the applicant has in good
        faith exercised any right under the Consumer Credit Protection Act. The
        Federal agency that administers compliance with this law concerning this
        creditor is The Federal Trade Commission, Equal Credit Opportunity, 600
        Pennsylvania Avenue, NW, Washington, DC 20580.
      </Typography>
    </OnboardingPage>
  );
};

export default AdverseActionAge;
