import { Account } from 'types/schemas';

/**
 * Calculate the amount that the user owes in dollars.
 */
export const convertCentsToDollars = (amountDue: number) => {
  if (amountDue > 0) {
    return amountDue / 100;
  }

  return 0;
};

/**
 * From dollars, calculate how many potential payment options the user can choose for a partial payment
 *
 * For $30, the number of options should be 3
 */
export const calculatePastDuePaymentOptionsLength = (
  pastDueAmountDollars: number,
) => {
  return Math.floor(pastDueAmountDollars / 10);
};

/**
 * For options length of 3, the result should be [10, 20]
 */
export const calculatePastDuePaymentOptions = (
  pastDuePaymentOptionsLength: number,
) => {
  return Array.from(
    { length: pastDuePaymentOptionsLength - 1 },
    (_, i) => (i + 1) * 10,
  );
};

/**
 * The past due amount is returned on the account in cents,
 * so we need to convert it to dollars
 */
export const calculateAmountPastDue = (account: Account) => {
  return account.pastDueAmount / 100;
};
