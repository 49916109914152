// TODO: move to hooks/mutations folder

import { API } from 'aws-amplify';
import { AxiosError } from 'axios';
import { useProductAnalytics } from 'libs/productAnalyticsTracking';
import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { FormValues } from 'components/ApplyForm/ApplyForm';
import { AddressData } from 'components/ApplyForm/InvalidAddressDialog';
import { AuthContext } from 'context/AuthProvider';
import { ApplicationStatus } from 'context/Profile';
import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { removeCookie } from 'helpers/cookies';
import { USE_APPLICATION_STATUS_QUERY_KEY } from 'hooks/queryKeys';
import { KovoError } from 'libs/KovoError';
import { logOnboardingEvent } from 'libs/logger';
import { INSTALLMENTS_PRODUCT_ID, ProductTypes, User } from 'types/schemas';
import useCurrentUser, {
  USE_CURRENT_USER_QUERY_KEY,
} from './queries/useCurrentUser';

type AddressErrorMetadata = {
  issues?: { componentType: string; confirmationLevel: string }[];
  missingComponentTypes?: string[];
  formattedAddress?: AddressData;
  errors?: { [key: string]: string[] }[];
};

// IDV Migration, new types
export type ApplicationSubmissionRequest = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dob: string;
  socialSecurityNumber: string;
  income: number;
} & AddressData;

export type ApplicationSubmissionStatus = 'SUBMITTED' | 'REJECTED';
export type RejectionCode =
  | 'UNPROCESSABLE_APPLICATION' // Applicant is younger than 16
  | 'REJECTED' // Applicant failed KYC
  // Retriable
  | 'SSN_VERIFICATION'
  | 'DOB_VERIFICATION'
  | 'ADDRESS_VERIFICATION'
  // Adverse Action Codes
  | 'INCOME' // Income too low
  | 'WATCHLIST' // Applicant appears on OFAC watchlist(s)
  | 'AGE'; // Applicant is younger than 18

export type RejectionDetails = {
  code: RejectionCode;
  message?: string;
};

export type ApplicationSubmissionResponse = {
  applicationId: string;
  status: ApplicationSubmissionStatus;
  rejection?: RejectionDetails;
};

export class KovoAddressUpdateError extends KovoError<AddressErrorMetadata> {}

const formatData = (data: FormValues) => {
  const monthlyIncome = data?.monthlyIncome
    ? parseInt(data.monthlyIncome.replace('$', '').replace(',', ''), 10)
    : 10;
  return {
    ...data,
    monthlyIncome,
  };
};

function useApplicationSubmitV2() {
  const { data: user } = useCurrentUser();
  const email = user?.currentEmail || '';
  const identityId = user?.userId || '';
  const username = user?.userPoolUserId || '';

  const history = useHistory();
  const queryClient = useQueryClient();
  const { track } = useProductAnalytics();

  const submitApplication = async (
    values: FormValues,
    accountType?: ProductTypes,
  ): Promise<ApplicationSubmissionResponse> => {
    try {
      const { applicationId, status, rejection } = await API.post(
        'installments',
        `/v1/applications/${accountType || 'loan_installment'}/submit`,
        {
          body: {
            ...formatData(values),
          },
          ...amplifyRequestContext(identityId, username),
        },
      );

      return { applicationId, status, rejection };
    } catch (error) {
      const applicationSubmitError = new KovoAddressUpdateError(
        'Error submitting application',
        {
          error,
        },
      );

      /**
       * The application submit endpoint returns a variety of different
       * responses depending on the type of error. We add the relevant
       * metadata to the error object so that the component that
       * renders the error can access it.
       */
      if (error instanceof AxiosError) {
        if (typeof error.response?.data.error === 'string') {
          applicationSubmitError.setDisplayMessage(error.response?.data.error);
        }
        if (error.response?.data.issues) {
          applicationSubmitError.addMetadata({
            issues: error.response.data.issues,
          });
        }
        if (error.response?.data.missingComponentTypes) {
          applicationSubmitError.addMetadata({
            missingComponentTypes: error.response.data.missingComponentTypes,
          });
        }
        if (error.response?.data.formattedAddress) {
          applicationSubmitError.addMetadata({
            formattedAddress: error.response.data.formattedAddress,
          });
        }
        if (error.response?.data.errors) {
          applicationSubmitError.addMetadata({
            errors: error.response.data.errors,
          });
        }
      }

      throw applicationSubmitError;
    }
  };

  const onSuccess = async ({
    status,
    rejection,
  }: ApplicationSubmissionResponse) => {
    // Refetch the current user and set its query data to the latest value
    // We could invalidate here, but we want to refetch/update cache immediately
    await queryClient.fetchQuery<User>(USE_CURRENT_USER_QUERY_KEY, {
      staleTime: 0,
      cacheTime: 0,
    });
    track({
      namespace: 'application',
      event: 'submission.succeeded',
      attributes: {
        accountType: 'loan_installment',
        loanProductId: INSTALLMENTS_PRODUCT_ID,
        digitalServiceId: 'service_1',
      },
      properties: {
        newIdvFlow: true,
      },
      sendAsConversionEventToMobileApp: true,
    });

    removeCookie('kovo_referral_code');
    removeCookie('kovo_marketing_attributions');

    logOnboardingEvent({
      eventName: 'application submitted',
      email,
    });

    await queryClient.invalidateQueries({
      queryKey: [USE_APPLICATION_STATUS_QUERY_KEY],
    });

    // Adverse action rejections
    if (status === 'REJECTED') {
      if (
        rejection?.code &&
        ['INCOME', 'WATCHLIST'].includes(rejection?.code)
      ) {
        logOnboardingEvent({
          eventName: 'application rejection adverse action',
          email,
        });

        return history.push('/adverse-action-notice');
      }

      if (rejection?.code === 'AGE') {
        logOnboardingEvent({
          eventName: 'application rejection adverse action under 18',
          email,
        });

        return history.push('/adverse-action-age');
      }
    }

    // Identity verification rejections
    if (
      status === 'REJECTED' &&
      rejection?.code &&
      ['SSN_VERIFICATION', 'DOB_VERIFICATION', 'ADDRESS_VERIFICATION'].includes(
        rejection?.code,
      )
    ) {
      logOnboardingEvent({
        eventName: 'application rejection cannot verify id',
        email,
      });

      return history.push('/application-rejected');
    }

    // Pending submission
    if (status === 'SUBMITTED') {
      return history.push('/apply-pending');
    }

    throw new KovoError(
      'Application status is not recognized. Please try again.',
    ).exposeMessage();
  };

  const onError = (error: KovoAddressUpdateError) => {
    window.scrollTo(0, 0);
  };

  return useMutation<
    ApplicationSubmissionResponse,
    KovoAddressUpdateError,
    FormValues
  >(submitApplication, {
    onSuccess,
    onError,
  });
}

export default useApplicationSubmitV2;
