import { API } from 'aws-amplify';
import { useQuery, UseQueryOptions } from 'react-query';

import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { USE_APPLICATION_STATUS_QUERY_KEY } from 'hooks/queryKeys';
import { KovoError } from 'libs/KovoError';
import useCurrentUser from './queries/useCurrentUser';
import { RejectionDetails } from './useApplicationSubmitV2';
import { ProductTypes } from 'types/schemas';

export type ApplicationStatus =
  | 'OPENED'
  | 'ACCEPTED'
  | 'REJECTED'
  | 'ABANDONED';

export type ApplicationStatusResponse = {
  applicationId: string;
  createdAt: string;
  updatedAt: string;
  status: ApplicationStatus;
  rejection?: RejectionDetails;
  approval?: {
    approvedAt: string;
  };
};

function useApplicationStatusV2(
  options?: UseQueryOptions<ApplicationStatusResponse> & {
    createdAt?: string;
    accountType?: ProductTypes;
  },
) {
  const { data: user } = useCurrentUser();

  const getApplicationStatus = async () => {
    try {
      if (!user) {
        throw new Error('User not found');
      }

      if (!options?.createdAt) {
        throw new Error('createdAt not provided');
      }

      const applicationStatus = await API.get(
        'installments',
        `/v1/applications/${user.userId}/${
          options?.accountType || 'loan_installment'
        }/${options.createdAt}/status`,
        amplifyRequestContext(user.userId, user.userPoolUserId),
      );

      return applicationStatus;
    } catch (error) {
      throw new KovoError('Error fetching application status')
        .addMetadata({
          userId: user?.userId,
        })
        .setError(error);
    }
  };

  return useQuery<ApplicationStatusResponse>({
    queryKey: [USE_APPLICATION_STATUS_QUERY_KEY],
    queryFn: getApplicationStatus,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!user && !!options?.createdAt,
    ...options,
  });
}

export default useApplicationStatusV2;
