import { Typography } from '@mui/material';
import { useContext } from 'react';
import { Redirect } from 'react-router-dom';

import OnboardingPage from 'components/OnboardingPage';
import ReferralCodeValidateBanner from 'components/ReferralCodeValidateBanner';
import SignUpConfirmForm from 'components/SignUpConfirmForm';
import { AuthContext } from 'context/AuthProvider';
import { theme } from 'context/ThemeProvider';
import { ROUTES } from 'components/Routes';

const SignUpConfirmPage: React.FC = () => {
  const { currentUser, logInCompleting, preConfirmationState } =
    useContext(AuthContext);

  if (!currentUser && !logInCompleting) {
    return <Redirect to="/login" />;
  }

  if (!preConfirmationState) {
    return <Redirect to={ROUTES.APP} />;
  }

  return (
    <OnboardingPage
      onboardingStep={1}
      supportSubject="Signup confirm"
      openSupportAsLink
    >
      <ReferralCodeValidateBanner />

      <Typography variant="h1" marginBottom={theme.spacing(1.5)}>
        Verify your email
      </Typography>

      <SignUpConfirmForm />
    </OnboardingPage>
  );
};

export default SignUpConfirmPage;
