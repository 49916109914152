import PageLoader from 'components/PageLoader';
import _useCurrentUser from 'hooks/queries/useCurrentUser';
import _useGetAccounts from 'hooks/queries/useGetAccounts';
import { FC, createContext, useContext } from 'react';
import { Redirect as _Redirect } from 'react-router-dom';
import { Account, ProductTypes } from 'types/schemas';

export interface AccountContextState {
  account?: Account;
  productType: ProductTypes;
}

export const AccountContext = createContext<AccountContextState>({
  account: {} as any,
  productType: 'loan_installment',
});

export const useAccountContext = () => {
  return useContext(AccountContext);
};

export type AccountProviderProps = {
  productType: ProductTypes;
  children: JSX.Element;
  /**
   * This is a very basic implementation of dependency injection.
   */
  useGetAccounts?: typeof _useGetAccounts;
  useCurrentUser?: typeof _useCurrentUser;
  Redirect?: typeof _Redirect;
};

export const AccountProvider = <P extends {} = {}>(
  props: P & AccountProviderProps,
) => {
  const {
    useGetAccounts = _useGetAccounts,
    useCurrentUser = _useCurrentUser,
    Redirect = _Redirect,
  } = props;

  const { data: accountsRes, isLoading } = useGetAccounts();
  const { data: user } = useCurrentUser();

  const { children, productType } = props;

  if (isLoading || !accountsRes || !user) {
    return <PageLoader />;
  }

  /**
   * Since Stripe users do not have installment accounts in loan service,
   * we should not redirect users to the account page if they are using Stripe
   * and are viewing installment info.
   */
  const redirectOnAccountNotFound =
    user.defaultPaymentProvider === 'checkout' ||
    props.productType !== 'loan_installment';

  const account = accountsRes.accounts.find(
    (account) => account.product.type === productType,
  );

  if (!account && redirectOnAccountNotFound) {
    // For some reason, if I import ROUTES into this file,
    // I begin to receive errors. The error is `default is not defined`
    // which is odd. For now, just hard coding.
    return <Redirect to={'/app'} />;
  }

  return (
    <AccountContext.Provider value={{ productType, account }}>
      {children}
    </AccountContext.Provider>
  );
};

const withAccount = <P extends {} = {}>(Component: FC<P>) => {
  return (props: P & { productType: ProductTypes }) => {
    return (
      <AccountProvider {...props}>
        <Component {...props} />
      </AccountProvider>
    );
  };
};

export default withAccount;
