import { DateTime } from 'luxon';
import React from 'react';
import * as yup from 'yup';

import DateInput, { dateValidation } from 'components/shared/DateInput';
import { dateInputFormat } from 'helpers/utils';

type Props = {
  value: string;
  error?: boolean;
  errorText?: string;
  setFieldValue: (field: string, value: string) => void;
  onBlur: (e: React.ChangeEvent<any>) => void;
  fullWidth?: boolean;
};

export const BIRTHDATE_INPUT_NAME = 'dob';
export const BIRTHDATE_INPUT_LABEL = 'Birth date (MM/DD/YYYY)';

/**
 * For a birth date, we want to make sure that the date is not in the future
 * in addition to the validation function from DateInput
 */
export const birthdateValidation = (value = '') => {
  const baseValidation = dateValidation(value);

  if (!baseValidation) {
    return false;
  }

  const now = DateTime.now();

  const d = DateTime.fromFormat(value, dateInputFormat);
  const isDateInPast = d < now;

  return isDateInPast;
};

/**
 * We also want to validate that a provided birth year is no earlier than 1901
 */
export const validateBirthyear = (value = '') => {
  const baseValidation = dateValidation(value);

  if (!baseValidation) {
    return false;
  }

  const date = DateTime.fromFormat(value, dateInputFormat);
  const minDate = DateTime.fromObject({ year: 1901, month: 1, day: 1 });
  return date >= minDate;
};

export const birthdateInputSchema = yup
  .string()
  .required('Birth date is required')
  .test(BIRTHDATE_INPUT_NAME, 'Invalid birth date', birthdateValidation)
  .test('minDate', 'Birth year appears to be incorrect', validateBirthyear);

export const BirthdateInput: React.FC<Props> = (props) => {
  return (
    <DateInput
      name={BIRTHDATE_INPUT_NAME}
      label={BIRTHDATE_INPUT_LABEL}
      {...props}
    />
  );
};

export default BirthdateInput;
