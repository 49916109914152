import { Box, Typography } from '@mui/material';
import { Redirect } from 'react-router-dom';

import OnboardingPage from 'components/OnboardingPage';
import PageLoader from 'components/PageLoader';
import { theme } from 'context/ThemeProvider';
import useApplicationStatus from 'hooks/useApplicationStatus';
import { ROUTES } from 'components/Routes';

const AdverseAction: React.FC = () => {
  const { data, isLoading } = useApplicationStatus();

  if (isLoading || !data) {
    return <PageLoader />;
  }

  if (
    data &&
    !['applicationAdverseAction', 'kycFailed', 'rejected'].includes(
      data.applicationStatus,
    )
  ) {
    return <Redirect to={ROUTES.APP} />;
  }

  return (
    <OnboardingPage supportSubject="adverse action">
      <Typography variant="h1" marginBottom={theme.spacing(2)}>
        Unable to proceed with your application
      </Typography>
      <Typography marginBottom={theme.spacing(1)}>
        Thanks for applying to the Installment Plan.
      </Typography>
      <Typography marginBottom={theme.spacing(1)}>
        After reviewing your information, we are unable to approve your
        application. If you would like a statement of specific reasons, please
        contact us below within 60 days of the date of this notice. We will
        provide you with the statement of reasons within 30 days after receiving
        your request.
      </Typography>

      <Box sx={{ paddingLeft: theme.spacing(2) }}>
        <Typography>Kovo Inc.</Typography>
        <Typography>9450 SW Gemini Dr, Suite 87907</Typography>
        <Typography>Beaverton, OR 97008-7105</Typography>
        <Typography marginBottom={theme.spacing(1)}>(855) 965-5686</Typography>
      </Box>

      <Typography marginBottom={theme.spacing(5)}>
        <strong>Please note</strong>: We did <strong>not</strong> run a credit
        check or review your credit report.
      </Typography>

      <Typography>
        <b>Notice:</b>&nbsp;The Federal Equal Credit Opportunity Act prohibits
        creditors from discriminating against credit applicants on the basis of
        race, color, religion, national origin, sex, marital status, age
        (provided the applicant has the capacity to enter into a binding
        contract); because all or part of the applicant's income derives from
        any public assistance program; or because the applicant has in good
        faith exercised any right under the Consumer Credit Protection Act. The
        Federal agency that administers compliance with this law concerning this
        creditor is The Federal Trade Commission, Equal Credit Opportunity, 600
        Pennsylvania Avenue, NW, Washington, DC 20580.
      </Typography>
    </OnboardingPage>
  );
};

export default AdverseAction;
