import { API } from 'aws-amplify';
import { useProductAnalytics } from 'libs/productAnalyticsTracking';
import { useContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { injectStripe, ReactStripeElements } from 'react-stripe-elements';

import StripeForm, { FormValues } from 'components/StripeForm';
import { AuthContext } from 'context/AuthProvider';
import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { logOnboardingEvent } from 'libs/logger';
import { ROUTES } from 'components/Routes';

interface MutateArgument {
  values: FormValues;
}

export const UpfrontPayment: React.FC<
  ReactStripeElements.InjectedStripeProps
> = ({ stripe, elements }) => {
  const { identityId, email, username } = useContext(AuthContext);
  const { track } = useProductAnalytics();
  const history = useHistory();

  const onSubmit = (values: FormValues) => {
    mutate({ values });
  };

  const getClientSecret = async () => {
    const { paymentIntentClientSecret } = await API.post(
      'billing',
      '/billing/upfront',
      {
        body: {
          items: ['upfront'],
        },
        ...amplifyRequestContext(identityId, username),
      },
    );

    return paymentIntentClientSecret;
  };

  const { data: paymentIntentClientSecret } = useQuery(
    ['clientSecret'],
    () => getClientSecret(),
    {
      refetchOnWindowFocus: false,
    },
  );

  const submitPayment = async ({ values: { name } }: MutateArgument) => {
    const cardNumberElement = elements?.getElement('cardNumber');
    const stripeRes = await stripe?.confirmCardPayment(
      paymentIntentClientSecret,
      {
        payment_method: {
          card: cardNumberElement!,
          billing_details: {
            name,
          },
        },
      },
    );

    if (stripeRes?.error) throw new Error(stripeRes?.error.message);

    const apiRes = await API.post(
      'installments',
      '/installments/confirmUpfrontPayment',
      {
        body: {},
        ...amplifyRequestContext(identityId, username),
      },
    );

    if (apiRes.error) throw new Error(apiRes.error);
  };

  const onSuccess = () => {
    track({
      namespace: 'application',
      event: 'upfrontpayment.succeeded',
      attributes: {
        digitalServiceId: 'service_1',
      },
    });
    logOnboardingEvent({
      eventName: 'upfront payment completed',
      email,
    });
    history.push(ROUTES.APP);
  };

  const { mutate, isLoading } = useMutation<void, Error, MutateArgument>(
    ({ values }) => submitPayment({ values }),
    {
      onSuccess,
    },
  );

  return (
    <StripeForm
      onSubmit={onSubmit}
      isLoading={isLoading}
      buttonText="Purchase"
    />
  );
};

export default injectStripe(UpfrontPayment);
