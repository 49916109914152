import { Redirect, useLocation } from 'react-router-dom';

import { ROUTES } from 'components/Routes';
import config from 'config';
import { isInstallmentAccountInGoodStanding } from 'helpers/accounts';
import useCurrentUser from 'hooks/queries/useCurrentUser';
import useGetAccounts from 'hooks/queries/useGetAccounts';
import useGetApplications from 'hooks/queries/useGetApplications';
import useApplicationStatus from 'hooks/useApplicationStatus';
import { UserStatus } from 'types/schemas';

export const CHARGE_ACCOUNT_STATUSES = [
  'completed',
  'paidEarly',
  'paidOnSchedule',
];

export const VALID_STRIPE_STATUSES: Array<UserStatus> = [
  'completed',
  'paidEarly',
  'paidOnSchedule',
];

const ChargeAccountRedirect: React.FC = () => {
  const { data: userData, isFetching: isFetchingUserData } = useCurrentUser();

  const { data, isFetching } = useGetApplications();

  const { data: applicationStatusData, isLoading } = useApplicationStatus();

  const { data: accountsData, isLoading: isLoadingAccounts } = useGetAccounts();

  const { pathname } = useLocation();

  if (
    !data ||
    !userData ||
    !applicationStatusData ||
    !accountsData ||
    isFetching ||
    isFetchingUserData ||
    isLoading ||
    isLoadingAccounts
  ) {
    return null;
  }

  const { chargeAccountApplication } = data;
  const hasAccessToChargeAccount = (): boolean => {
    if (config.VITE_STAGE !== 'prod') {
      return true;
    }

    // If their charge account is completed
    if (chargeAccountApplication?.status === 'completed') {
      return true;
    }

    // Otherwise, we need to check several things
    // If they have charge account enabled
    if (!userData.chargeAccountEnabled) {
      return false;
    }

    // If their application status is one of these
    if (
      !CHARGE_ACCOUNT_STATUSES.includes(
        applicationStatusData?.applicationStatus,
      )
    ) {
      return false;
    }

    if (userData.defaultPaymentProvider === 'stripe') {
      // For Stripe users, installmentAccount will be null so we need to check their status instead
      return VALID_STRIPE_STATUSES.includes(userData.status);
    }

    // For Checkout users, let's check their installment account
    return isInstallmentAccountInGoodStanding(accountsData.installmentAccount);
  };

  if (!hasAccessToChargeAccount()) {
    return <Redirect to={ROUTES.APP} />;
  }

  if (!chargeAccountApplication || chargeAccountApplication.status === 'new') {
    return <Redirect to={ROUTES.CREDIT_LINE_HOW_IT_WORKS} />;
  }

  if (chargeAccountApplication.status === 'started') {
    if (pathname === ROUTES.CREDIT_LINE_HOW_IT_WORKS) {
      return null;
    }

    return <Redirect to={ROUTES.CREDIT_LINE_CONTRACT} />;
  }

  if (chargeAccountApplication.status === 'esignViewed') {
    return <Redirect to={ROUTES.CREDIT_LINE_PAYMENT} />;
  }

  if (chargeAccountApplication.status === 'completed') {
    return <Redirect to={ROUTES.CREDIT_LINE} />;
  }

  return null;
};

export default ChargeAccountRedirect;
